<template>
  <div class="grzx">
    <div class="top">
      任务状态查询：
      <el-select v-model="value" @change="init(pageSize,0)" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="content">
      <el-table
        border
        row-key="id"
        class="el-tables"
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="childTaskName" label="子任务名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{scope.row.childTaskName}}</div>
              <div slot="reference" class="name-wrapper">
                <div>{{scope.row.childTaskName}}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.status==0">未领取</div>
            <div v-if="scope.row.status==1">未启动</div>
            <div v-if="scope.row.status==2">加工中</div>
            <div v-if="scope.row.status==3">校对中</div>
            <div v-if="scope.row.status==4">审核中</div>
            <div v-if="scope.row.status==5">已完成</div>
            <div v-if="scope.row.status==6">已逾期</div>
            <div v-if="scope.row.status==7">已违约</div>
          </template>
        </el-table-column>
        <el-table-column prop="drawTime " label="工期（截至）">
          <template slot-scope="scope">
            <!-- 工期 -->
            <div>{{scope.row.childTaskDay }}天</div>
            <div>{{scope.row.endTime}}</div>
          </template>
        </el-table-column>
        <el-table-column label="分配进度整体">
          <template slot-scope="scope">
            <span>{{scope.row.FPJD}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="加工进度整体">
          <template slot-scope="scope">
            <span>{{scope.row.JGJD}}%</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <span
              class="buttons buttons3"
              @click="jiagong(scope.row.taskType,scope.row.id,scope.row.taskId,scope.row.status )"
            >加工</span>
            <span
              v-if="scope.row.status==0||scope.row.status==1||scope.row.status==2"
              class="buttons buttons1"
              @click="tankuangs(scope.row.childTaskMoney,scope.row.id,scope.row.taskId,scope.row.taskType,scope.row.childTaskName,scope.row.status)"
            >退出</span>
            <!-- <el-button type="info" class="buttonss" plain disabled v-else>退出</el-button> -->
            <span v-else class="buttons tuichubuttons1">退出</span>
            <span class="buttons buttons2" @click="deleted(scope.row.id,scope.row.status)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="prev">
        <!-- :hide-on-single-page="true" -->
        <!-- @prev-click="currentPage"
        @next-click="currentPage" -->
        <el-pagination
          @current-change="currentPage"
          background
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹框 -->
    <div class="heyueshu" v-if="tankuang">
      <div class="heyue_center">
        <div class="heyue_top">
          <span class="zrwfp_title1" v-if="TC_or_ZF">退出提示</span>
          <span class="zrwfp_title1" v-if="!TC_or_ZF">支付违约金</span>
          <span class="yin1" @click="close"></span>
        </div>
        <div class="zhengwen" v-if="TC_or_ZF">
          <div class="tuichu line_70">提示：退出任务将赔付{{Money}}知识币</div>
          <div class="tuichu">
            <span class="buttons buttons1" @click="tuichu">退出</span>
          </div>
        </div>
        <div class="zhengwen" v-if="!TC_or_ZF">
          <div
            class="tuichu1 line_40"
            style="text-align: left;"
          >您的账户余额为{{balance}}知识币，您确认支付{{Money}}知识币查看违约金退出任务吗？</div>
          <div class="tuichu1" style="margin-top:10px;text-align: center;">
            <span class="buttons buttons1" @click="close">取消</span>
            <span class="buttons buttons5" @click="Zftuichu">退出</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from "@/services/http.js";
import { dateType, YearMMdd } from "@/js/common/index.js";
import { formatZero, getTimeFormat } from "@/js/common/Resoures";
import {
  DELETE,
  QUERY,
  UPDATES,
  UPDATE,
  QUERYED,
  INSERT
} from "@/services/dao.js";
export default {
  data() {
    return {
      TC_or_ZF: false, //退出还是支付
      tankuang: false, //是否显示弹框
      options: [
        {
          value: -1,
          label: "全部"
        },
        // {
        //   value: "0",
        //   label: "未领取"
        // },
        {
          value: "1",
          label: "未启动"
        },
        {
          value: "2",
          label: "加工中"
        },
        {
          value: "3",
          label: "校对中"
        },
        {
          value: "4",
          label: "审核中"
        },
        {
          value: "5",
          label: "已完成"
        },
        {
          value: "6",
          label: "已逾期"
        },
        {
          value: "7",
          label: "已违约"
        }
      ],
      value: -1,
      tableData: [
        {
          id: "1",
          name: "张三",
          state: "已启动",
          FPJD: "100%",
          JGJD: "100%",
          time: "2021"
        }
      ],
      Zhidr: [
        {
          value: "1",
          label: "张三"
        },
        {
          value: "2",
          label: "李四"
        },
        {
          value: "3",
          label: "王五"
        }
      ], //指定人
      people: "", //指定人
      pageSize: 5, //条数
      total: 0, //总条数
      pageNum: 0, ////当前页数
      Money: "", //退出赔付金额
      balance: "", ///账户余额
      DJmoney: "", //冻结余额
      zrwid: 0, //子任务ID
      zrwname: "", //子任务名称
      taskType: "" //任务类型
    };
  },
  created() {
    this.init(this.pageSize, 0);
  },
  methods: {
    // 删除
    async deleted(id, states) {
      if (states == 5) {
        ////任务已完成
        let info = await DELETE(
          "post",
          "",
          "delete_jgChildTask(where: {id : {_eq: " +
            id +
            "}}) {   affected_rows  }"
        );
        if (info.data.delete_jgChildTask.affected_rows == 1) {
          this.$message({
            message: "删除成功！",
            type: "success"
          });
        } else {
          this.$message.error("删除失败！");
        }
      } else {
        this.$message({
          message: "任务未完成不可删除哦！",
          type: "warning"
        });
      }
    },
    //加工
    async jiagong(type, id, taskId, status) {
      if (status == 2 || status == 3) {
        if (type == 1) {
          //纸质化
          if (status == 2) {
            this.$router.push({
              path: "/entering",
              query: {
                id: id,
                type: 1 //任务负责人
              }
            });
          } else if (status == 3) {
            this.$router.push({
              path: "/entering",
              query: {
                id: id,
                type: 2 //任务负责人
              }
            });
          }
        } else if (type == 2) {
          //数字化
          ///通过总任务id:taskId；查找ype；
          let info = await QUERYED(
            "post",
            "",
            "jgDigitalPro(where: {id   : {_eq:" + taskId + " }}){type }"
          );
          if (info.data.jgDigitalPro.length >= 1) {
            window.location.href =
              "http://www.izhihui.net/api/user/add/token?token=" +
              localStorage.getItem("Authorization") +
              "&targetUrl=http://www.izhihui.net/zz/#/home/work/" +
              taskId +
              "/inf?type=" +
              info.data.jgDigitalPro[0].type +
              "";
          }
        } else if (type == 3) {
          window.location.href =
            "http://www.izhihui.net/eybak/#/subTask?userId=" +
            this.$store.state.userinfo.id +
            "&taskId=" +
            taskId +
            "&token=" +
            localStorage.getItem("Authorization");
          //词条
        }
      } else {
        this.$message({
          message: "不可去加工！",
          type: "warning"
        });
      }
    },
    // 初始化
    async init(limit, offset) {
      console.log('开始',new Date());
      // alert(this.value)
      this.tableData = [];
      let info = null;
      if (this.value == -1) {
        ///查询全部
        info = await QUERYED(
          "post",
          "",
          "jgChildTask(limit: " +
            limit +
            ", offset: " +
            offset +
            ", where: {JG_delete  : {_neq: 1},jgUserId  : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){childTaskName id fzrId  status endTime  childTaskDay taskId taskType  childTaskMoney drawTime } jgChildTask_aggregate (where: {jgUserId : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){     aggregate {       count     }   }"
        );
      } else {
        ///分类查询
        info = await QUERYED(
          "post",
          "",
          "jgChildTask(limit: " +
            limit +
            ", offset: " +
            offset +
            ", where: {JG_delete  : {_neq: 1},jgUserId : {_eq:" +
            this.$store.state.userinfo.id +
            ' },status : {_eq:"' +
            this.value +
            '" }}){childTaskName id endTime fzrId  childTaskDay status taskId taskType  childTaskMoney drawTime } jgChildTask_aggregate (where: {jgUserId : {_eq:' +
            this.$store.state.userinfo.id +
            " }}){     aggregate {       count     }   }"
        );
      }
      console.log("我加工的", info.data.jgChildTask);
      this.total = info.data.jgChildTask_aggregate.aggregate.count; //
      console.log(this.total,13);
      for (let j = 0; j < info.data.jgChildTask.length; j++) {
        let FPJD = "";
        let JGJD = "";
        ///负责人存在
        //纸质化加工
        let zrw = await QUERYED(
          "post",
          "",
          "jgChildTask_aggregate (where: {taskId  : {_eq:" +
            info.data.jgChildTask[j].taskId +
            "},fzrId:{_eq:" +
            info.data.jgChildTask[j].fzrId +
            "}}){     aggregate {       count     }   }"
        );
        let fpwc = await QUERYED(
          "post",
          "",
          "jgChildTask_aggregate (where: {fzrId   : {_eq:" +
            info.data.jgChildTask[j].fzrId +
            "},taskId  : {_eq:" +
            info.data.jgChildTask[j].taskId +
            ' },_not: {status: {_eq: "0"}}}){     aggregate {       count     }   } '
        );
        if (
          fpwc.data.jgChildTask_aggregate.aggregate.count == 0 &&
          zrw.data.jgChildTask_aggregate.aggregate.count == 0
        ) {
          FPJD = 0;
        } else {
          FPJD =
            (Number(fpwc.data.jgChildTask_aggregate.aggregate.count) /
              Number(zrw.data.jgChildTask_aggregate.aggregate.count)) *
            100;
        }
        if (info.data.jgChildTask[j].taskType == 1) {
          //纸质化加工
          let jgwc = await QUERYED(
            "post",
            "",
            "jgChildTask_aggregate (where: {fzrId   : {_eq:" +
              info.data.jgChildTask[j].fzrId +
              "},taskId  : {_eq:" +
              info.data.jgChildTask[j].taskId +
              ' },status   : {_eq:"5"}}){     aggregate {       count     }   } '
          );
          if (
            jgwc.data.jgChildTask_aggregate.aggregate.count == 0 &&
            zrw.data.jgChildTask_aggregate.aggregate.count == 0
          ) {
            JGJD = 0;
          } else {
            // 加工进度
            JGJD =
              (Number(jgwc.data.jgChildTask_aggregate.aggregate.count) /
                Number(zrw.data.jgChildTask_aggregate.aggregate.count)) *
              100;
          }
        } 
        // else if (info.data.jgChildTask[j].taskType == 2) {
        //   //数字化
        //   await get("/common/SzcpProgressQuery", {
        //     taskId: info.data.jgChildTask[j].taskId
        //   })
        //     .then(e => {
        //       JGJD = e.data; //课题二加工
        //       console.log("数字产品", JGJD);
        //     })
        //     .catch(function(error) {
        //       console.log("错误" + error);
        //     });
        // } else if (info.data.jgChildTask[j].taskType == 3) {
        //   //词条
        //   await post("/common/EntryProgressQuery", {
        //     taskId: info.data.jgChildTask[j].taskId,
        //     type: 0
        //   })
        //     .then(e => {
        //       JGJD = e.data; //课题三加工
        //       console.log("词条", JGJD);
        //     })
        //     .catch(function(error) {
        //       console.log("错误" + error);
        //     });
        // }
        FPJD = Math.round(FPJD);
        JGJD = Math.round(JGJD);
        this.tableData.push({
          id: info.data.jgChildTask[j].id,
          taskId: info.data.jgChildTask[j].taskId, //总任务ID
          status: info.data.jgChildTask[j].status, //状态
          childTaskName: info.data.jgChildTask[j].childTaskName, //日期
          childTaskMoney: info.data.jgChildTask[j].childTaskMoney, //子任务酬劳
          drawTime: YearMMdd(info.data.jgChildTask[j].drawTime), //时间
          taskType: info.data.jgChildTask[j].taskType, //分类
          childTaskDay: info.data.jgChildTask[j].childTaskDay, //工期
          endTime:
            info.data.jgChildTask[j].endTime == null
              ? ""
              : YearMMdd(info.data.jgChildTask[j].endTime), //工期截止时间
          // taskType: info.data.jgChildTask[j].taskType //状态
          FPJD: FPJD == "" ? 0 : FPJD,
          JGJD:
            info.data.jgChildTask[j].status == 5 ? 100 : JGJD == "" ? 0 : JGJD
        });
      }
      
      console.log('结束',new Date());
    },




    //关闭弹框
    close() {
      this.tankuang = false;
    },
    async qrtuichu(id) {
      ////修改任务状态为已违约
      // let weiyue = await UPDATE(
      //   "post",
      //   "",
      //   "update_jgChildTask(where: {id: {_eq: " +
      //     id +
      //     '}}, _set: {status : "7",jgUserId  : ' +
      //     null +
      //     ",jgUserName  :" +
      //     null +
      //     "}) { affected_rows  }"
      // );
      let weiyue = await UPDATE(
        "post",
        "",
        "update_jgChildTask(where: {id: {_eq: " +
          id +
          '}}, _set: {status : "7"}) { affected_rows  }'
      );
      if (weiyue.data.update_jgChildTask.affected_rows == 1) {
        this.$message({
          type: "success",
          message: "退出成功!"
        });
        this.init(this.pageSize, this.pageNum); //刷新
      }
    },
    //显示弹框
    async tankuangs(Money, id, taskId, taskType, name, status) {
      if (status == 1 || status == 0) {
        this.$confirm("是否继续退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.qrtuichu(id); //确定退出
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.tankuang = !this.tankuang; //隐藏
        this.TC_or_ZF = true; //显示退出消息
        this.Money = Money; //显示金额
        this.zrwid = id; //子任务ID
        this.rwid = taskId; //任务ID
        this.taskType = Number(taskType) - 1; //任务类型
        this.zrwname = name; //子任务名称
      }
    },
    // 退出按钮
    async tuichu() {
      let info = await QUERYED(
        "post",
        "",
        "User( where: {id : {_eq:" +
          this.$store.state.userinfo.id +
          " }}){Money zsjg_prepay }"
      );
      this.TC_or_ZF = false; //显示退出支付提示
      this.balance = info.data.User[0].Money; ///账户余额
      this.DJmoney = info.data.User[0].zsjg_prepay; //冻结余额
    },
    // 退出支付
    async Zftuichu() {
      ///子任务余额this.zrwid
      this.tankuang = false; //关闭弹框
      if (Number(this.balance) < Number(this.Money)) {
        this.$alert("您的余额不足，请先到个人中心充值！", "系统提示：", {
          confirmButtonText: "确定",
          showClose: false,
          distinguishCancelAndClose: true
        });
      }
      ////先扣除我的知识币
      ///可用余额减去薪酬///(我的)
      ///冻结余额加上薪酬////（发布者的）
      // ,zsjg_prepay: " +
      //     Number(this.DJmoney) +
      //     Number(this.Money) +
      //     "
      else {
        ///余额足够
        let info = await UPDATE(
          "post",
          "",
          "update_User(where: {id: {_eq: " +
            this.$store.state.userinfo.id +
            "}}, _set: {Money  : " +
            Number(Number(this.balance) - Number(this.Money)) +
            "}) { affected_rows  }"
        );
        console.log("this.rwid", this.rwid, "this.taskType", this.taskType);
        ////通过任务ID：this.rwid；任务状态：this.taskType；查找任务发布者id 负责人ID
        let fbzid = await QUERYED(
          "post",
          "",
          "JGSearch( where: {id : {_eq:" +
            this.rwid +
            " },tasktype : {_eq:" +
            this.taskType +
            " }}){userid jgFzrAndTaskId }"
        );
        console.log("123123123", fbzid.data.JGSearch[0]);
        ////发布者ID:fbzid.data.JGSearch[0].userid
        ////通过发布者ID查找发布者的冻结余额
        let fbzye = await QUERYED(
          "post",
          "",
          "User( where: {id : {_eq:" +
            fbzid.data.JGSearch[0].userid +
            " }}){zsjg_prepay }"
        );
        ////修改发布者的冻结余额
        let xgfbz = await UPDATE(
          "post",
          "",
          "update_User(where: {id: {_eq: " +
            fbzid.data.JGSearch[0].userid +
            "}}, _set: {Money  : " +
            Number(
              Number(fbzye.data.User[0].zsjg_prepay) + Number(this.Money)
            ) +
            "}) { affected_rows  }"
        );
        ////修改账户余额和冻结余额
        if (
          info.data.update_User.affected_rows == 1 &&
          xgfbz.data.update_User.affected_rows
        ) {
          ///修改子任务的任务状态
          let weiyue = await UPDATE(
            "post",
            "",
            "update_jgChildTask(where: {id: {_eq: " +
              this.zrwid +
              '}}, _set: {status : "7"}) { affected_rows  }'
          );
          if (weiyue.data.update_jgChildTask.affected_rows == 1) {
            ////生成账单
            ////用户ID
            let ddata1 = await QUERYED(
              "post",
              "",
              "  WDALLBill(where: {userid: {_eq: " +
                this.$store.state.userinfo.id +
                "}}) {  time  orderNum  id   }"
            );
            // 生成扣除加工者账单的订单编号
            let orderNum1 = "";
            let num1 = String(formatZero(this.$store.state.userinfo.id, 7));
            /////生成订单编号
            if (ddata1.data.WDALLBill.length < 1) {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            } else {
              for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
                if (
                  getTimeFormat(new Date()) ==
                  getTimeFormat(ddata1.data.WDALLBill[i].time)
                ) {
                  let sic =
                    ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
                  orderNum1 =
                    getTimeFormat(new Date()) +
                    num1 +
                    String(formatZero(sic, 3));
                } else {
                  orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                }
              }
            }
            ////我的支出账单.getTime()
            let zczd = await INSERT(
              "post",
              "",
              '  insert_bill(objects: {type: 4, state: 0, time: "' +
                new Date() +
                '", userid: ' +
                this.$store.state.userinfo.id +
                ', orderNum: "' +
                orderNum1 +
                '", orderid: ' +
                this.zrwid +
                ', Price: "' +
                this.Money +
                '", evaluate: "",classification:1}) {affected_rows}'
            );
            if (zczd.data.insert_bill.affected_rows == 1) {
              ////发送消息通知
              ///任务发布者
              let fbz = await INSERT(
                "post",
                "",
                " insert_jgMessage(objects: {messageType:6,userId : " +
                  fbzid.data.JGSearch[0].userid +
                  ",money: " +
                  this.Money +
                  ",taskType: " +
                  7 +
                  ",taskId: " +
                  this.zrwid +
                  ",userType: " +
                  1 +
                  ',taskName: "' +
                  this.zrwname +
                  '",taskTime : "' +
                  new Date().getTime() +
                  '",taskTypeTow : ' +
                  this.taskType +
                  "}) { affected_rows  }"
              );
              ///给负责人发送一条消息通知
              if (fbz.data.insert_jgMessage.affected_rows == 1) {
                let fzr = await INSERT(
                  "post",
                  "",
                  " insert_jgMessage(objects: {messageType:6,userId : " +
                    fbzid.data.JGSearch[0].jgFzrAndTaskId +
                    ",money: " +
                    this.Money +
                    ",taskType: " +
                    7 +
                    ",taskId: " +
                    this.zrwid +
                    ",userType: " +
                    1 +
                    ',taskName: "' +
                    this.zrwname +
                    '",taskTime : "' +
                    new Date().getTime() +
                    '",taskTypeTow : ' +
                    this.taskType +
                    "}) { affected_rows  }"
                );
                if (fzr.data.insert_jgMessage.affected_rows == 1)
                  this.$message({
                    message: "退出成功！",
                    type: "success"
                  });
                this.init(this.pageSize, this.pageNum); //刷新
              } else {
                this.$message.error("退出失败！");
              }
            }
          }
        }
      }
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>
<style scoped>
.el-tables {
  width: 100%;
  /* border: 1px solid #000000; */
  margin-top: 30px;
}
.el-select {
  /* margin-top: 20px; */
}
.top {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  padding-top: 30px;
}
::v-deep .el-table .warning-row {
  /* background: rgb(221, 211, 191); */
}
::v-deep .el-table .success-row {
  /* background: #8ab673; */
}
/* ::v-deep table {
  border-left: 1px solid #000000;
} */
/* ::v-deep thead
{
background-color: #b92424;
} */
/* ::v-deep table thead tr th {
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
} */
/* ::v-deep tbody tr td {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
::v-deep .el-table th.is-leaf {
  border-bottom: 1px solid #000000;
} */
.el-table::before {
  z-index: auto;
  /* background-color: red; */
}
.buttons {
  width: 55px;
  height: 27px;
  line-height: 27px;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.buttonss {
  width: 55px;
  height: 30px;
  /* line-height: 3px; */
  text-align: center;
  position: relative;
  top: -5px;
  /* margin-left: 5px; */
}
::v-deep .buttonss > span {
  position: absolute;
  left: 13px;
  top: 7px;
  font-size: 15px;
}
.buttons1 {
  background-color: #e5e5e5;
  color: #000000;
  border: 1px solid #afafaf;
}
.tuichubuttons1 {
  background-color: #f5f4f4;
  color: #6e6e6e;
  border: 1px solid #afafaf;
  cursor:no-drop !important;
}
.buttons2 {
  background-color: #f8d5d8;
  color: #ba0f2e;
  margin-left: 5px;
  border: 1px solid #f0a7ad;
}
.buttons3 {
  background-color: #d6edfb;
  color: #000502;
  margin-right: 5px;
  border: 1px solid #a5d1fa;
  /* border:1px solid #0068B7; */
}
.buttons4 {
  background-color: #ffffff;
  color: #0084ff;
  margin-left: 5px;
  border: 1px solid #a5d1fa;
  /* border:1px solid #0068B7; */
}
.buttons5 {
  background-color: #0084ff;
  color: #feffff;
  margin-left: 130px;
  border: 1px solid #3e94f7;
  /* border:1px solid #0068B7; */
}
.content {
  width: 100%;
  /* border: 1px solid red; */
}
.prev {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.zrwfp {
  width: 750px;
  /* border: 1px solid red; */
  margin: auto;
  margin-top: 50px;
}
.zrwfp_top {
  width: 748px;
  height: 50px;
  background-color: #eeeeee;
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
  border-top: 1px solid #000000;
  padding: 20px 0px 0px 0px;
}
.zrwfp_top > div {
  width: 726px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 27px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  padding: 0px 10px 0px 12px;
}
.zrwfp_title {
  font-weight: bold;
  color: #000000;
}
.zrwfp_title1 {
  /* border: 1px solid red; */
  width: 335px;
  font-size: 18px;
  display: inline-block;
  color: #000000;
  text-align: center;
  font-weight: bold;
}
.zrwfp_fzr {
  font-weight: 400;
  color: #3e94f7;
}
.red {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c10015;
  line-height: 52px;
  text-align: center;
}
.font {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-decoration: underline;
  color: #3e94f7;
}
/* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
.zhengwen {
  /* border: 1px solid red; */
  /* width: 480px;
  height: 490px; */
  width: 371px;
  height: 138px;
  display: inline-block;
  padding: 20px;
  background-color: #ffffff;
}
.heyue_top {
  width: 100%;
  /* height: 18px; */
  padding: 10px 0px 10px 0px;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: right;
  border-bottom: 1px solid #434343;
  background: #eeeeee;
}
.heyue_center {
  width: 411px;
  height: 230px;
  background: #ffffff;
  background-color: #e0e0e0;
  border: 1px solid #434343;
  /* padding: 10px; */
}
.heyueshu {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line_70 {
  line-height: 70px;
}
.line_40 {
  line-height: 30px;
}
.tuichu1 {
  width: 80%;
  height: 70px;
  /* border: 1px solid red; */

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin: auto;
}
.tuichu {
  width: 100%;
  height: 70px;
  /* border: 1px solid red; */
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
/* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 23px 23px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
.queding {
  margin-top: 15px;
  /* text-align: center; */
  padding-left: 30px;
}
.grzx >>> .el-table__header {
  /* border: 2px solid red; */
  width: 100% !important;
}
.grzx >>> .el-table__body {
  width: 100% !important;
}
/* 查看按钮 */
.buttonss {
  /* margin-left: 5px; */
  width: 57px;
  height: 30px;
  /* line-height: 3px; */
  text-align: center;
  position: relative;
  top: 5px;
  background-color: #1c9beb;
  color: #000502;
  /* margin-left: 5px; */
  border: 1px solid #a5d1fa;
  /* margin-left: 5px; */
}
::v-deep .buttonss > span {
  position: relative;
  /* left: -5.7px;
  top: -5px; */
  /* font-size: 15px; */
}
.text_cent {
  text-align: center;
  /* border: 1px solid red; */
}
</style>